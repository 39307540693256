import Vue from 'vue'
import Router from 'vue-router'
import Register from './views/register.vue'
import Login from './views/login.vue'
import Emoji from './views/emoji.vue'
import Api from './views/api.vue'
import Strategy from './views/strategy.vue'
import Telegram from './views/telegram.vue'
import Audit from './views/audit.vue'

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/login'
    }, {
      path: '/register',
      name: 'register',
      component: Register
    }, {
      path: '/login',
      name: 'login',
      component: Login
    }, {
      path: '/emoji',
      name: 'emoji',
      component: Emoji
    }, {
      path: '/api',
      name: 'api',
      component: Api
    }, {
      path: '/strategy',
      name: 'strategy',
      component: Strategy
    }, {
      path: '/telegram',
      name: 'telegram',
      component: Telegram
    }, {
      path: '/audit',
      name: 'audit',
      component: Audit
    }
  ]
})
