<template>
  <div class="register">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px">
      <el-form-item label="邮箱" prop="email">
        <el-input type="text" v-model="ruleForm.email" autocomplete="off" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input ref="password" type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"
                  placeholder="英文、数字及部分符号"></el-input>
      </el-form-item>
      <el-form-item label="GA" prop="googleAuth">
        <el-input type="text" v-model="ruleForm.googleAuth" autocomplete="off" placeholder="谷歌验证码"></el-input>
      </el-form-item>
      <div class="googleAuthQRCode">
        <p>使用谷歌验证码App扫码或<button class="code" @click="copyCode()">复制密钥</button></p>
        <canvas id="googleAuthQRCode">换一个主流的浏览器吧</canvas>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">清空</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import statistics from '@/api/statistics'
import sha256 from 'js-sha256'
import QRCode from 'qrcode'
import utils from '@/utils/index'

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      }
      if (value.length < 8 || value.length > 128) {
        callback(new Error('密码长度必须为8~128位之间'));
      }
      if (!/^[\w`\-=[\]\\;',./~!@#$%^&*()_+{}|:"<>?]{8,128}$/.test(value)) {
        callback(new Error('密码不能包含特殊字符'));
      }
      if (this.ruleForm.checkPass !== '') {
        this.$refs.ruleForm.validateField('checkPass');
      }
      callback();
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请确认密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入不一致'));
      } else {
        callback();
      }
    };
    const validateGoogleAuth = (rule, value, callback) => {
      if (!/^\d{6}$/.test(value)) {
        callback(new Error('请输入6位谷歌验证码'));
      } else {
        callback();
      }
    };
    return {
      token: this.$route.query.token,
      gaCode: '',
      formLabelWidth: '120px',
      ruleForm: {
        email: '',
        pass: '',
        checkPass: '',
        googleAuth: ''
      },
      rules: {
        pass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'}
        ],
        googleAuth: [
          {validator: validateGoogleAuth, trigger: 'blur'}
        ],
      }
    };
  },
  components: {},

  // 页面实例开始创建调用
  created() {
    this.prepare();
  },

  methods: {
    async prepare() {
      const params = {
        token: this.token || ''
      };
      const resp = await statistics.registerPrepare(params);
      const data = resp.data;
      if (data) {
        this.ruleForm.email = data.email;
        const canvas = document.getElementById('googleAuthQRCode');
        QRCode.toCanvas(canvas, 'otpauth://totp/' + data.email + '?secret=' + data.googleAuth + '&issuer=' + location.host, function (error) {
        });
        this.googleAuth = data.googleAuth
        this.$refs.password.focus();
      } else {
        this.$router.replace({path: 'emoji', query: {msg: '404. Error', img: 'error'}});
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const params = {
            token: this.token,
            password: sha256(this.ruleForm.pass + "_password_salt_" + this.ruleForm.email),
            gaCode: this.ruleForm.googleAuth
          };
          const resp = await statistics.register(params);
          if (resp.code === 0) {
            this.$router.replace({path: 'emoji', query: {msg: '账号初始化完成', img: 'success'}});
            setTimeout(() => {
              this.$router.replace({path: 'login'});
            }, 2000);
          }
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      const email = this.ruleForm.email;
      this.$refs[formName].resetFields();
      this.ruleForm.email = email;
      this.$refs.password.focus();
    },

    copyCode() {
      utils.copyToClipboard(this.googleAuth, '密钥已复制到剪切板')
    },
  }
}
</script>
<style scoped lang="scss">
.register {
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

  > .el-form {
    width: 300px;
    margin-left: -80px;
  }
}

.googleAuthQRCode {
  padding-left: 60px;
  text-align: center;
  padding-bottom: 20px;

  .code {
    padding: 0;
  }
}
</style>
