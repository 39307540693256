<template>
  <div class="telegram">
    <p v-if="chatId">
      你已经绑定过了，如果要重新绑定也可重复操作
    </p>
    <p>
      添加Telegram机器人@achain_private_bot，向机器人发送如下消息进行绑定:
    </p>
    <p>
      <span>{{ token }}</span>
    </p>
    <p>
      绑定后可以通过机器人接收通知消息
    </p>
    <p>
      机器人链接 <a href="https://t.me/achain_private_bot" target="_blank">https://t.me/achain_private_bot</a>
    </p>
  </div>
</template>
<script>
import statistics from '@/api/statistics'

export default {
  data() {
    return {
      token: '',
      chatId: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prepare();
    })
  },


  methods: {
    async prepare() {
      const resp = await statistics.telegramBindPrepare();
      if (resp.code === 0) {
        this.token = 'bind_' + resp.data.token;
        this.chatId = resp.data.chatId;
      }
    },
  }
}
</script>
<style scoped lang="scss">
.telegram {
  padding: 30px;
  width: 100%;
  justify-content: center;
}
</style>
