/**
 * get传参数需要以 params 为key
 * post 直接发送数据即可
 */
import {api} from './base'

export default {
  serverInfo(params = {}) {
    return api.post('/server/info', params)
  },

  registerPrepare(params = {}) {
    return api.post('/user/register/prepare', params)
  },
  register(params = {}) {
    return api.post('/user/register/confirm', params)
  },

  loginCheck(params = {}) {
    return api.post('/user/login/check', params)
  },
  loginPass(params = {}) {
    return api.post('/user/login/pass', params)
  },
  loginGA(params = {}) {
    return api.post('/user/login/ga', params)
  },
  logout(params = {}) {
    return api.post('/user/logout', params)
  },

  telegramBindPrepare(params = {}) {
    return api.post('/telegram/bind/prepare', params)
  },

  apiList(params = {}) {
    return api.post('/api/list', params)
  },
  apiSave(params = {}) {
    return api.post('/api/save', params)
  },
  apiDelete(params = {}) {
    return api.post('/api/delete', params)
  },
  apiStat(params = {}) {
    return api.post('/api/stat', params)
  },

  strategyList(params = {}) {
    return api.post('/strategy/list', params)
  },
  strategySave(params = {}) {
    return api.post('/strategy/save', params)
  },
  strategyDelete(params = {}) {
    return api.post('/strategy/delete', params)
  },
  strategyDeleteForce(params = {}) {
    return api.post('/strategy/delete/force', params)
  },
  strategyStatus(params = {}) {
    return api.post('/strategy/status', params)
  },
  strategyQuery(params = {}) {
    return api.post('/strategy/query', params)
  },
  strategyCancelPendingOrders(params = {}) {
    return api.post('/strategy/pending-cancel', params)
  },

  auditQuery(params = {}) {
    return api.post('/audit/query', params)
  },
  auditSubmit(params = {}) {
    return api.post('/audit/submit', params)
  },
}

