<template>
  <div class="login" v-if="loginType === 'password'">
    <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="80px">
      <el-form-item label="邮箱" prop="email">
        <el-input ref="email" type="text" v-model="loginForm.email" autocomplete="off"
                  @keyup.enter.native="enterOnEmail()"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input ref="pass" type="password" v-model="loginForm.pass" autocomplete="off"
                  @keyup.enter.native="login()"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login()" :loading="loading">登录</el-button>
        <el-button @click="resetForm('loginForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>

  <div class="googleAuth" v-else-if="loginType === 'googleAuth'">
    <el-form :model="gaForm" status-icon :rules="rules" ref="gaForm" label-width="30px">
      <el-form-item label="GA" prop="googleAuth">
        <el-input type="text" ref="gaCode" v-model="gaForm.googleAuth" autocomplete="off"
                  placeholder="输入谷歌验证码" @keyup.enter.native="loginGA()"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loginGA()" :loading="loading">确定</el-button>
        <el-button @click="resetForm('gaForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import statistics from '@/api/statistics'
import sha256 from 'js-sha256'

export default {
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]+))$/.test(value)) {
        callback(new Error('请输入正确的邮箱'));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 8 || value.length > 128) {
        callback(new Error('密码长度应该在8~128位之间'));
      }
      if (!/^[\w`\-=[\]\\;',./~!@#$%^&*()_+{}|:"<>?]{8,128}$/.test(value)) {
        callback(new Error('密码应该不包含特殊字符'));
      } else {
        callback();
      }
    };
    const validateGoogleAuth = (rule, value, callback) => {
      if (this.loginType === 'googleAuth' && !/^\d{6}$/.test(value)) {
        callback(new Error('请输入6位谷歌验证码'));
      } else {
        callback();
      }
    };
    return {
      token: this.$route.query.token,
      loading: false,
      formLabelWidth: '120px',
      loginType: 'password',
      loginForm: {
        email: '',
        pass: '',
      },
      gaForm: {
        googleAuth: '',
      },
      rules: {
        email: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        pass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        googleAuth: [
          {validator: validateGoogleAuth, trigger: 'blur'}
        ],
      }
    };
  },
  components: {},

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.loginCheck();
    })
  },

  // 页面实例开始创建调用
  created() {
  },

  methods: {
    async loginCheck() {
      this.loading = false;
      this.loginType = 'password';
      const resp = await statistics.loginCheck();
      if (resp.code === 0 && resp.data) {
        this.$message({
          message: '已登录',
          type: 'success'
        });
        this.defaultAfterLogin();
      } else {
        this.$refs.email.focus();
      }
    },

    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.doLogin();
        }
        return valid;
      });
    },

    async doLogin() {
      if (this.loading || this.loginType !== 'password')
        return;
      const params = {
        email: this.loginForm.email,
        password: sha256(this.loginForm.pass + "_password_salt_" + this.loginForm.email),
      };
      this.loading = true;
      const resp = await statistics.loginPass(params);
      this.loading = false;
      if (resp.code === 0) {
        this.loginType = 'googleAuth';
        this.$nextTick(() => {
          this.$refs.gaCode.focus();
        });
      }
    },

    loginGA() {
      this.$refs.gaForm.validate(async (valid) => {
        if (valid) {
          if (this.loading || this.loginType !== 'googleAuth')
            return;
          const params = {
            gaCode: this.gaForm.googleAuth,
          };
          this.loading = true;
          const resp = await statistics.loginGA(params);
          this.loading = false;
          if (resp.code === 0) {
            this.loginType = 'password';
            this.$message({
              message: '登录成功',
              type: 'success'
            });
            this.defaultAfterLogin();
          } else if (resp.code === 501) {
            this.loginType = 'password';
          }
        }
        return valid;
      });
    },

    defaultAfterLogin() {
      window.eventHub.fire('afterLogin');
    },

    enterOnEmail() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.doLogin();
        } else {
          this.$refs.pass.focus();
        }
        return valid;
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>
<style scoped lang="scss">
.login {
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

  > .el-form {
    margin-left: -80px;
  }
}

.googleAuth {
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
