import Vue from 'vue'
import router from './router'
import './plugins/element.js'
import App from './App.vue'
import EventHub from '@/utils/eventHub'


Vue.config.productionTip = false;

window.eventHub = new Vue();
const eventHub = EventHub.createEvents(window);
window.eventHub = eventHub;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

//router.beforeEach((to, from, next) => {
//    next();
//});

router.afterEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  window.eventHub.fire('refreshNav');
});
