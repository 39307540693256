<template>
  <div class="strategy-list">
    <el-table :data="tableData.filter(data => this.search(data))" stripe v-loading="loading">
      <el-table-column fixed sortable prop="remark" label="策略名" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column sortable prop="typeRemark" label="策略" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column sortable prop="apiExchanger" label="交易所" width="88" show-overflow-tooltip></el-table-column>
      <el-table-column sortable prop="apiRemark" label="API" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column sortable prop="order" label="代币" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column sortable prop="payment" label="计价" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column sortable label="额外信息" width="280" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ extraInfo(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column width="180" min-width="78">
        <template slot="header" slot-scope="scope">
          <el-input :clearable=true v-model="searchKeyWord" size="mini" placeholder="搜索"/>
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="mini" @click="handleQuery(scope.$index, scope.row)">查看</el-button>
            <el-button size="mini" @click="handleExchangeWeb(scope.$index, scope.row)">市场</el-button>
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column width="180">
        <template slot="header" slot-scope="scope">
          <el-button size="mini" type="success" @click="handleNew(scope.$index, scope.row)">新增</el-button>
        </template>
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          <el-popconfirm
              confirmButtonText='好的'
              cancelButtonText='不了'
              icon="el-icon-warning"
              iconColor="red"
              @confirm="deleteData(scope.row.id)"
              title="确定删除吗？">
            <el-button slot="reference" size="mini" type="danger" plain>删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column width="76">
        <template slot-scope="scope">
          <el-popconfirm v-if="scope.row.status === 'DEFAULT'"
                         confirmButtonText='好的'
                         cancelButtonText='不了'
                         icon="el-icon-warning"
                         iconColor="green"
                         @confirm="updateStatus(scope.row.id, 'RUNNING')"
                         title="确定启动策略吗？">
            <el-button slot="reference" size="mini" type="success" plain>启动</el-button>
          </el-popconfirm>
          <el-popconfirm v-if="scope.row.status === 'RUNNING'"
                         confirmButtonText='好的'
                         cancelButtonText='不了'
                         icon="el-icon-warning"
                         iconColor="red"
                         @confirm="updateStatus(scope.row.id, 'DEFAULT')"
                         title="确定停止策略吗？">
            <el-button slot="reference" size="mini" type="warning" plain>停止</el-button>
          </el-popconfirm>
          <el-popconfirm v-if="scope.row.status === 'INVALID'"
                         confirmButtonText='好的'
                         cancelButtonText='不了'
                         icon="el-icon-warning"
                         iconColor="red"
                         @confirm="updateStatus(scope.row.id, 'RUNNING')"
                         title="策略出错了，仍然要启动策略吗？">
            <el-button slot="reference" size="mini" type="warning" plain>启动</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column sortable prop="statusRemark" label="状态" width="auto"
                       show-overflow-tooltip></el-table-column>
    </el-table>

    <el-dialog :fullscreen="true" title="策略" :visible.sync="dialogFormVisible" class="strategy-dialog">
      <el-form :model="dataEdit" status-icon :rules="rowEditRules" ref="dataEdit" label-width="auto" size="mini"
               :hide-required-asterisk="true" :disabled="viewMode">
        <el-row type="flex">
          <el-col>
            <el-form-item label="名称" prop="remark">
              <el-input v-model="dataEdit.remark" autocomplete="off" placeholder="名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="API" prop="apiId">
              <el-select v-model="dataEdit.apiId" placeholder="选择交易所API">
                <el-option
                    v-for="item in exchangeApiList"
                    v-bind:key="item.id"
                    :label="item.remark"
                    :value="item.id">
                  <span style="float: left">{{ item.remark }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.exchanger }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'A', 'M2', 'M3', 'M4', 'M5', 'M6')">
            <el-form-item label="API2" prop="asOtherApiId">
              <el-select v-model="dataEdit.asOtherApiId" placeholder="选择另一个交易所API">
                <el-option
                    v-for="item in exchangeApiList"
                    v-bind:key="item.id"
                    :label="item.remark"
                    :value="item.id">
                  <span style="float: left">{{ item.remark }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.exchanger }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col v-if="isType(dataEdit.type, 'D', 'M5', 'M6')">
            <el-form-item label="对敲API2" prop="dsOtherApiId">
              <el-select v-model="dataEdit.dsOtherApiId" placeholder="对敲的另一个API">
                <el-option
                  v-for="item in exchangeApiList"
                  v-bind:key="item.id"
                  :label="item.remark"
                  :value="item.id">
                  <span style="float: left">{{ item.remark }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.exchanger }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col>
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker v-model="dataEdit.startTime" type="datetime" placeholder="">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker v-model="dataEdit.endTime" type="datetime" placeholder="">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col>
            <el-form-item label="策略" prop="type">
              <el-select v-model="dataEdit.type" placeholder="选择挂单策略">
                <el-option
                    v-for="item in strategyTypes"
                    v-bind:key="item.value"
                    :label="item.desc"
                    :value="item.value">
                  <span style="float: left">{{ item.desc }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="!isType(dataEdit.type, 'D', 'M6') || isType(dataEdit.type, 'D4')">
            <el-form-item label="间隔(秒)" prop="rate">
              <el-input v-model="dataEdit.rate" autocomplete="off" placeholder="策略执行间隔（秒）"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'M2', 'M3', 'M4', 'M5', 'M6')">
            <el-form-item :label="isType(dataEdit.type,  'M6') ? '期望运行间隔(秒)' : '取价间隔(秒)'" prop="msRate">
              <el-input v-model="dataEdit.msRate" autocomplete="off" :placeholder="isType(dataEdit.type,  'M6') ? '实际运行间隔会随机减去0~5秒' : '取价间隔(秒)'"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'A')">
            <el-form-item label="挂单间隔(秒)" prop="msRate">
              <el-input v-model="dataEdit.msRate" autocomplete="off" placeholder="挂单间隔（秒）"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" v-if="viewMode && isType(dataEdit.type, 'D')">
          <el-col>
            <el-form-item label="今已刷" prop="dsDayAmount">
              <el-input v-model="dataEdit.dsDayAmount" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="今成交" prop="dsDealAmountToday">
              <el-input v-model="dataEdit.dsDealAmountToday" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="24h成交" prop="dsDealAmount24h">
              <el-input v-model="dataEdit.dsDealAmount24h" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col>
            <el-form-item label="代币" prop="order">
              <el-input v-model="dataEdit.order" autocomplete="off" placeholder="基准货币"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="计价" prop="payment">
              <el-autocomplete v-model="dataEdit.payment" autocomplete="off" placeholder="计价货币"
                               :fetch-suggestions="queryPaymentSuggest"
                               @select="handlePaymentSuggestSelect">
                <i slot="suffix"></i>
                <template slot-scope="{ item }">
                  <div style="text-overflow: ellipsis; overflow: hidden;">{{ item.value }}</div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>


        <!--

              @RequestParam(value = "msSpan", required = false) BigDecimal msSpan,
              @RequestParam(value = "msPriceRate", required = false) BigDecimal msPriceRate,
        -->

        <el-row v-if="isType(dataEdit.type, 'M2', 'M3', 'M4', 'M5', 'M6')" type="flex">
          <el-col>
            <el-form-item label="挂钩代币" prop="msOrder">
              <el-input v-model="dataEdit.msOrder" autocomplete="off" placeholder="挂钩基准货币"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="挂钩计价" prop="pmsPayment">
              <el-input v-model="dataEdit.msPayment" autocomplete="off" placeholder="挂钩计价货币"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="挂钩价格系数" prop="msPriceRate">
              <el-input v-model="dataEdit.msPriceRate" autocomplete="off"
                        placeholder="挂钩币种的价格 * 系数得到当前摆盘【目标价格】"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="价格汇率系数" prop="pmsPayment">
              <el-select v-model="dataEdit.str1" placeholder="">
                <el-option
                    v-for="item in exchangeRates"
                    v-bind:key="item.pair"
                    :label="item.pair || item.desc"
                    :value="item.pair">
                  <span style="float: left">{{ item.pair || item.desc }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px; width: 200px; text-align: right">{{
                      item.rate
                    }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col v-if="isType(dataEdit.type, 'M5', 'M6')">
            <el-form-item label="价格波动限制系数" prop="decimal1">
              <el-input v-model="dataEdit.decimal1" autocomplete="off" placeholder="0.01"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="isType(dataEdit.type, 'M2', 'M4')" type="flex">
          <el-col>
            <el-form-item label="买1卖1与【目标价格】间距" prop="msSpan">
              <el-input v-model="dataEdit.msSpan" autocomplete="off" placeholder="如果间距为系数，则间距为取到的价格乘上该参数；否则，需要填差价的整数倍"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="挂单价格小数位数" prop="msSpan">
              <el-input v-model="dataEdit.int1" autocomplete="off" placeholder="如果间距为系数，则该参数必填"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="间距、差价为系数" prop="bool1">
              <el-checkbox v-model="dataEdit.bool1"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col>
            <el-form-item label="差价" prop="diff">
              <el-autocomplete v-model="dataEdit.diff" autocomplete="off" placeholder="一般用最小单位"
                               :fetch-suggestions="queryDiffSuggest"
                               @select="handleQueryDiffSuggestSelect">
                <i slot="suffix"></i>
                <template slot-scope="{ item }">
                  <div style="text-overflow: ellipsis; overflow: hidden;">{{ item.value }}</div>
                  <span style="font-size: 12px; color: #b4b4b4;">{{ item.desc }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'S', 'D', 'M2', 'M3', 'M4', 'M5', 'M6')">
            <el-form-item label="最小价格" prop="minPrice">
              <el-input v-model="dataEdit.minPrice" autocomplete="off" placeholder="限制卖"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'B', 'D', 'M2', 'M3', 'M4', 'M5', 'M6')">
            <el-form-item label="最大价格" prop="maxPrice">
              <el-input v-model="dataEdit.maxPrice" autocomplete="off" placeholder="限制买"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'S1', 'B1')">
            <el-form-item label="强挂间隔" prop="forceDealSec">
              <el-input v-model="dataEdit.forceDealSec" autocomplete="off" placeholder="强制挂单间隔(秒)"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'D', 'M6') && !isType(dataEdit.type, 'D4')">
            <el-form-item label="每日数量" prop="dsLimitAmount">
              <el-input v-model="dataEdit.dsLimitAmount" autocomplete="off" placeholder="每日刷多少量"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'D', 'M5', 'M6') && !isType(dataEdit.type, 'D4')">
            <el-form-item label="数量为金额" prop="bool1">
              <el-checkbox v-model="dataEdit.bool1"></el-checkbox>
            </el-form-item>
          </el-col>


          <el-col v-if="isType(dataEdit.type, 'M1')">
            <el-form-item label="买1价格" prop="minPrice">
              <el-input v-model="dataEdit.minPrice" autocomplete="off" placeholder="从买1往下挂"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'M1')">
            <el-form-item label="卖1价格" prop="maxPrice">
              <el-input v-model="dataEdit.maxPrice" autocomplete="off" placeholder="从卖1往上挂"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'M1', 'M2', 'M4')">
            <el-form-item label="挂单数量" prop="msCount">
              <el-input v-model="dataEdit.msCount" autocomplete="off" placeholder="单侧挂单数量"></el-input>
            </el-form-item>
          </el-col>


          <el-col v-if="isType(dataEdit.type, 'A')">
            <el-form-item label="计价2" prop="msPayment">
              <el-input v-model="dataEdit.msPayment" autocomplete="off" placeholder="API2计价代币"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'A')">
            <el-form-item label="P2系数" prop="decimal1">
              <el-input v-model="dataEdit.decimal1" autocomplete="off"
                        placeholder="系数r: P2 * r 与 P1 比较"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="isType(dataEdit.type, 'A')">
            <el-form-item label="P2汇率" prop="pmsPayment">
              <el-select v-model="dataEdit.str1" placeholder="汇率:e P2 * e 与 P1 比较">
                <el-option
                    v-for="item in exchangeRates"
                    v-bind:key="item.pair"
                    :label="item.pair || item.desc"
                    :value="item.pair">
                  <span style="float: left">{{ item.pair || item.desc }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px; width: 200px; text-align: right">{{
                      item.rate
                    }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col style="padding-right: 10px">
            <el-form-item label="最小数量" prop="minAmount">
              <el-input v-model="dataEdit.minAmount" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col style="padding-right: 10px" v-if="!isType(dataEdit.type, 'D2')">
            <el-form-item label="最大数量" prop="maxAmount">
              <el-input v-model="dataEdit.maxAmount" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col style="padding-right: 10px" v-if="isType(dataEdit.type, 'B', 'S')">
            <el-form-item label="重挂数量" prop="redoAmount">
              <el-input v-model="dataEdit.redoAmount" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="小数位数" prop="amountScale">
              <el-autocomplete v-model="dataEdit.amountScale" autocomplete="off"
                               :fetch-suggestions="queryAmountScaleSuggest"
                               @select="handleQueryAmountScaleSuggestSelect">
                <i slot="suffix"></i>
                <template slot-scope="{ item }">
                  <div style="text-overflow: ellipsis; overflow: hidden;">{{ item.value }}</div>
                  <span style="font-size: 12px; color: #b4b4b4;">{{ item.desc }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" v-if="isType(dataEdit.type, 'B1')">
          <el-col>
            <el-form-item label="余额数量限制" prop="decimal1">
              <el-input v-model="dataEdit.decimal1" autocomplete="off" placeholder="余额数量如果>=就不买了"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="数量为金额" prop="bool1">
              <el-checkbox v-model="dataEdit.bool1"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="isType(dataEdit.type, 'D6', 'D7')" type="flex">
          <el-col style="padding-right: 10px">
            <el-form-item label="价格区间" prop="msSpan">
              <el-input v-model="dataEdit.msSpan" autocomplete="off"
                        placeholder="取到目标价格加上或减去这个确定挂单区间"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="padding-right: 10px">
            <el-form-item label="间隔(分钟)" prop="msCount">
              <el-input v-model="dataEdit.msCount" autocomplete="off" placeholder="注意取价间隔为分钟"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateData()" v-loading="loading" v-if="!viewMode">确 定</el-button>
        <el-popconfirm v-if="viewMode"
                       confirmButtonText='好的'
                       cancelButtonText='不了'
                       icon="el-icon-warning"
                       iconColor="green"
                       @confirm="cancelAllPendingOrders()"
                       title="确定取消该交易对全部挂单吗？">
          <el-button type="warning" v-loading="cancelOrderLoading" slot="reference" style="margin: 12px;">撤 单
          </el-button>
        </el-popconfirm>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import statistics from "../api/statistics";

export default {
  data() {
    return {
      exchangeApiList: [],
      exchangeRates: [],
      exchangePaymentCurrencies: {},
      strategyTypes: [],
      tableData: [],
      dialogFormVisible: false,
      loading: true,
      cancelOrderLoading: false,
      viewMode: true,
      dataEdit: {},
      searchKeyWord: '',
      rowEditRules: {
        remark: [
          {required: true, message: '请输入名称', trigger: 'blur'},
          {min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur'}
        ],
        apiId: [
          {required: true, message: '请选择交易所API', trigger: 'blur'},
        ],
        asOtherAipId: [
          {required: true, message: '请选择另一个交易所API', trigger: 'blur'},
        ],
        order: [
          {required: true, message: '请输入基准货币', trigger: 'blur'},
          {min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur'}
        ],
        payment: [
          {required: true, message: '请输入计价货币', trigger: 'blur'},
          {min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择策略类型', trigger: 'blur'},
        ],
        rate: [
          {required: true, message: '请输入策略重复间隔（秒）', trigger: 'blur'}
        ],
        diff: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        minAmount: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        maxAmount: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        redoAmount: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        minPrice: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        maxPrice: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        amountScale: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        dsLimitAmount: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        msCount: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        msOrder: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        msPayment: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        msPriceRate: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        msSpan: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getData();
    })
  },

  methods: {
    async getData() {
      this.loading = true;
      const res = await statistics.strategyList();

      if (res.code === 0) {
        this.tableData = res.data.list;
        this.exchangeApiList = res.data.exchangeApis.list;
        this.exchangePaymentCurrencies = res.data.exchangeApis.exchangePaymentCurrencies;
        this.strategyTypes = res.data.strategyTypes;
        // 汇率选择
        this.exchangeRates = res.data.exchangeRates;
        this.exchangeRates.push({desc: "无汇率"})
      }
      this.loading = false;
    },

    updateData() {
      if (this.loading) {
        this.$message({
          message: '数据加载中',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      this.$refs['dataEdit'].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const res = await statistics.strategySave(this.copyRowData(this.dataEdit, true));
          if (res.code === 0) {
            this.$message({
              message: '更新成功',
              type: 'success'
            });
            this.dialogFormVisible = false;
            this.getData().then();
            return true;
          }
        }
        this.loading = false;
        return false;
      });
    },

    async deleteData(id) {
      if (this.loading) {
        this.$message({
          message: '数据加载中',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      const res = await statistics.strategyDelete({id: id});
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.dialogFormVisible = false;
        await this.getData();
      } else {
        this.$confirm('删除失败，要强制删除吗', {
          type: 'warning',
          callback: async (action) => {
            if (action === 'confirm') {
              this.loading = true;
              const res = await statistics.strategyDeleteForce({id: id});
              if (res.code === 0) {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                });
                this.dialogFormVisible = false;
                await this.getData();
              }
              this.loading = false;
            }
          }
        })
      }
      this.loading = false;
    },

    async updateStatus(id, status) {
      if (this.loading) {
        this.$message({
          message: '数据加载中',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      const res = await statistics.strategyStatus({id: id, status: status});

      if (res.code === 0) {
        this.$message({
          message: '更新成功',
          type: 'success'
        });
        this.dialogFormVisible = false;
        await this.getData();
      }
      this.loading = false;
    },

    search(row) {
      if (!this.searchKeyWord) {
        return true;
      }
      const words = this.searchKeyWord.toLowerCase().split(' ');
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (word && !this.__searchRow(row, word)) {
          return false;
        }
      }
      return true;
    },

    __searchRow(row, word) {
      return row.remark && row.remark.toLowerCase().includes(word) ||
          row.typeRemark && row.typeRemark.toLowerCase().includes(word) ||
          row.apiExchanger && row.apiExchanger.toLowerCase().includes(word) ||
          row.apiRemark && row.apiRemark.toLowerCase().includes(word) ||
          row.order && row.order.toLowerCase().includes(word) ||
          row.payment && row.payment.toLowerCase().includes(word) ||
          row.statusRemark && row.statusRemark.toLowerCase().includes(word);
    },

    queryPaymentSuggest(queryString, cb) {
      let results = [];
      if (this.dataEdit.apiId) {
        const api = this.exchangeApiList.find(api => api.id === this.dataEdit.apiId);
        if (api) {
          this.exchangePaymentCurrencies[api.exchanger].forEach(c => {
            results.push({value: c.toLowerCase()});
          });
        }
      }
      cb(results);
    },

    async cancelAllPendingOrders() {
      if (this.cancelOrderLoading) {
        this.$message({
          message: '撤单中',
          type: 'warning'
        });
        return;
      }
      this.cancelOrderLoading = true;
      const res = await statistics.strategyCancelPendingOrders({id: this.dataEdit.id});
      if (res.code === 0) {
        this.$message({
          message: `撤单成功:${res.data.successCount}，失败:${res.data.failureCount}`,
          type: 'success'
        });
      }
      this.cancelOrderLoading = false;
    },

    handlePaymentSuggestSelect(item) {
      this.$refs['dataEdit'].clearValidate('payment');
    },

    queryDiffSuggest(queryString, cb) {
      this.querySuggest(queryString, cb, 'diff');
    },

    handleQueryDiffSuggestSelect(item) {
      this.$refs['dataEdit'].clearValidate('diff');
    },

    queryAmountScaleSuggest(queryString, cb) {
      this.querySuggest(queryString, cb, 'amountScale');
    },

    handleQueryAmountScaleSuggestSelect(item) {
      this.$refs['dataEdit'].clearValidate('amountScale');
    },

    querySuggest(queryString, cb, key) {
      let results = [];
      const set = {};
      if (this.dataEdit[key]) {
        set[this.dataEdit[key]] = true;
      }
      if (this.dataEdit.apiId &&
          this.dataEdit.order &&
          this.dataEdit.payment) {

        this.tableData.forEach(row => {
          if (this.dataEdit.id !== row.id &&
              this.dataEdit.apiId === row.apiId &&
              this.dataEdit.order.trim().toUpperCase() === row.order &&
              this.dataEdit.payment.trim().toUpperCase() === row.payment) {
            const v = row[key];
            if (v && !set[v]) {
              set[v] = true;
              results.push({
                value: v.toString(),
                desc: row.remark,
              });
            }
          }
        });
      }
      cb(results);
    },

    handleNew(index, row) {
      this.dataEdit = {};
      this.dialogFormVisible = true;
      this.viewMode = false;
    },
    handleEdit(index, row) {
      this.dataEdit = this.copyRowData(row);
      this.dialogFormVisible = true;
      this.viewMode = false;
    },

    handleExchangeWeb(index, row) {
      const win = window.open(row.webExchange, '_blank');
      if (win) {
        // win.focus();
      } else {
        alert('弹窗被阻止了');
      }
    },

    async handleQuery(index, row) {
      this.loading = true;
      const res = await statistics.strategyQuery({id: row.id});
      if (res.code === 0) {
        this.dataEdit = this.copyRowData(row);
        this.setInfoData(this.dataEdit, res.data);
        this.dialogFormVisible = true;
        this.viewMode = true;
      }
      this.loading = false;
    },

    copyRowData(row, save) {
      return {
        id: row.id,
        remark: row.remark && row.remark.trim(),
        apiId: row.apiId,
        order: row.order && row.order.trim(),
        payment: row.payment && row.payment.trim(),
        type: row.type,
        rate: this.convertRate(row, save),
        diff: row.diff,
        amountScale: row.amountScale && row.amountScale.toString(),
        minAmount: row.minAmount,
        maxAmount: row.maxAmount,
        redoAmount: row.redoAmount,
        minPrice: row.minPrice,
        maxPrice: row.maxPrice,
        startTime: this.convertDate(row.startTime, save),
        endTime: this.convertDate(row.endTime, save),
        dsLimitAmount: row.dsLimitAmount || 0,
        forceDealSec: row.forceDealSec,
        msCount: row.msCount,
        msOrder: row.msOrder,
        msPayment: row.msPayment,
        msPriceRate: row.msPriceRate,
        msRate: row.msRate,
        msSpan: row.msSpan,
        asOtherApiId: row.asOtherApiId,
        str1: row.str1,
        decimal1: row.decimal1,
        bool1: row.bool1,
        int1: row.int1,
        dsOtherApiId: row.dsOtherApiId,
      }
    },

    convertRate(row, save) {
      if (save) {
        if (this.isType(row.type, 'D') && row.type !== 'D4' || row.type === 'M6') {
          return 1000
        } else {
          return row.rate * 1000
        }
      } else {
        return row.rate / 1000
      }
    },

    convertDate(time, save) {
      return time ? (save ? time.getTime() : new Date(time)) : null;
    },

    setInfoData(row, data) {
      row.order += ' ' + data.orderCurrencyInfo;
      row.payment += ' ' + data.paymentCurrencyInfo;
      row.dsDayAmount = data.dsDayAmount;
      row.dsDealAmountToday = data.dsDealAmountToday;
      row.dsDealAmount24h = data.dsDealAmount24h;
    },

    isType(type, ...s) {
      if (!type) return false
      for (let i = 0; i < s.length; i++) {
        if (type.startsWith(s[i])) {
          return true;
        }
      }
      return false;
    },

    extraInfo(row) {
      let info = ''
      if (this.isType(row.type, 'M2', 'M3', 'M4', 'M5', 'M6')) {
        if (row.msPriceRate) {
          info = this.extraInfoConcat(info, '系数: ' + row.msPriceRate)
        }
        if (row.str1) {
          info = this.extraInfoConcat(info, ' 汇率: ' + this.getExchangeRate(row.str1) + '(' + row.str1 + ')')
        }
      } else if (this.isType(row.type, "A1")) {
        if (row.decimal1) {
          info = this.extraInfoConcat(info, '系数: ' + row.decimal1)
        }
        if (row.str1) {
          info = this.extraInfoConcat(info, ' 汇率: ' + this.getExchangeRate(row.str1) + '(' + row.str1 + ')')
        }
      }
      if (this.isType(row.type, 'M5', 'M6', 'D')) {
        for (let i = 0; i < this.exchangeApiList.length; i++) {
          const api = this.exchangeApiList[i]
          if (api.id === row.dsOtherApiId) {
            info = this.extraInfoConcat(info, '对敲API: ' + api.remark)
          }
        }
      }
      return info
    },

    extraInfoConcat(info, add) {
      if (info) {
        return info + ' ' + add
      } else {
        return add
      }
    },

    getExchangeRate(pair) {
      for (let i = 0; i < this.exchangeRates.length; i++) {
        if (this.exchangeRates[i].pair === pair) {
          let r = this.exchangeRates[i].rate
          if (r > 1) {
            return r.toFixed(2)
          } else {
            return r.toPrecision(2)
          }
        }
      }
      return '?'
    }
  }
}
</script>

<style scoped lang="scss">
.strategy-list {
  padding: 2px;
  width: 100%;
}

.strategy-dialog {
  justify-content: center;
}

.query-dialog {
  justify-content: center;
}
</style>
