<template>
  <div class="apply" v-if="text.length === 0">
    <div>代币：{{ apply.token }}({{ apply.chain }})</div>
    <div>地址：{{ apply.address }}</div>
    <div>数量：{{ apply.amount }}</div>
    <div>申请人：{{ apply.applier }}</div>
    <div>原因：</div>
    <p class="reason">
      {{ apply.reason }}
    </p>
    <div class="button">
      <el-button type="success" @click="auditSubmit(true)" :loading="pass && loading">通过</el-button>
      <el-button type="danger" @click="auditSubmit(false)" :loading="!pass && loading">拒绝</el-button>
    </div>
  </div>
  <div v-else class="apply">{{ text }}</div>
</template>
<script>
import statistics from '@/api/statistics'

export default {
  data() {
    return {
      token: this.$route.query.token,
      id: this.$route.query.id,
      apply: {},
      loading: false,
      text: '数据加载中...',
      pass: true
    };
  },
  components: {},

  created() {
    this.auditQuery()
  },

  methods: {
    async auditQuery() {
      if (this.loading) return
      this.loading = true
      try {
        const resp = await statistics.auditQuery({
          id: this.id,
          token: this.token
        });
        if (resp.code === 0) {
          this.apply = resp.data
          this.text = ''
          this.apply.reason = '　　' + this.apply.reason
        } else {
          this.text = `数据加载失败：${resp.msg}`
        }
      } finally {
        this.loading = false
      }
    },
    async auditSubmit(pass) {
      if (this.loading) return
      const txt = pass ? '通过' : '拒绝'
      const input = prompt(`请输入 "${txt}" 确认`)
      if (input !== txt) {
        if (input !== null) message.warn(`输入错误: ${input}`)
        return
      }
      this.pass = pass
      this.loading = true
      try {
        const resp = await statistics.auditSubmit({
          id: this.id,
          token: this.token,
          pass
        });
        if (resp.code === 0 && resp.data) {
          this.apply = resp.data
          this.$message({
            message: '审批已' + txt,
            type: 'success'
          });
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.apply {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  font-size: x-large;
}

.reason {
  font-size: large;
}

.button {
  margin-top: 20px;
}
</style>
