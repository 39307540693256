<template>
  <div id="div" class="emoji">
    <h1 id="h1">我是谁？我在哪？</h1>
    <img class="img" id="img_error" src="@/assets/img/error.jpg" alt="">
    <img class="img" id="img_lose" src="@/assets/img/lose.jpg" alt="">
    <img class="img" id="img_warn" src="@/assets/img/warn.jpg" alt="">
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: this.$route.query.msg,
      img: this.$route.query.img
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.draw();
    })
  },

  watch: {
    img: (newval, oldVal) => {
      console.log('img: %s, old: %s', newval, oldVal)
    }
  },

  methods: {
    draw() {
      const msg = this.msg;
      if (msg) {
        document.title = msg;
        document.getElementById("h1").innerHTML = msg;
      }
      const img = this.img;
      if (img === 'error') {
        document.getElementById("img_error").style.display = 'block';
      } else if (img === 'warn') {
        document.getElementById("img_warn").style.display = 'block';
      } else {
        document.getElementById("img_lose").style.display = 'block';
      }
    },
  }
}
</script>
<style scoped lang="scss">
.emoji {
  width: 100%;
  margin-top: 10px;
  text-align: center;
}

.img {
  display: none;
  margin: auto;
}
</style>
