<template>
  <div class="api-list">
    <el-table :data="tableData" stripe v-loading="loading">
      <el-table-column fixed prop="remark" label="备注" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="exchanger" label="交易所" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="apiKey" label="KEY" width="300" show-overflow-tooltip></el-table-column>
      <el-table-column>
        <template slot="header" slot-scope="scope">
          <el-button size="mini" type="success" @click="handleNew()">新增</el-button>
          <el-button size="mini" v-loading="ipLoading" type="success" @click="handleShowIp()">IP</el-button>
          <el-button size="mini" v-loading="statLoading" type="success" @click="handleStat()">统计</el-button>
        </template>
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          <el-popconfirm
              confirmButtonText='好的'
              cancelButtonText='不了'
              icon="el-icon-warning"
              iconColor="red"
              @confirm="deleteData(scope.row.id)"
              title="确定删除吗？">
            <el-button slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="API" :visible.sync="dialogFormVisible" v-loading="loading" class="api-dialog">
      <el-form :model="dataEdit" status-icon :rules="rowEditRules" ref="dataEdit" label-width="66px">
        <el-form-item label="名称" prop="remark">
          <el-input v-model="dataEdit.remark" autocomplete="off" placeholder="备注信息"></el-input>
        </el-form-item>
        <el-form-item label="交易所" prop="exchanger">
          <el-select v-model="dataEdit.exchanger" placeholder="请选择交易所" value="">
            <el-option
                v-for="item in exchangers"
                :value="item">
              <span style="float: left">{{ item }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Key" prop="apiKey">
          <el-input v-model="dataEdit.apiKey" autocomplete="off" placeholder="API Key"></el-input>
        </el-form-item>
        <el-form-item label="Secret" prop="apiSecret">
          <el-input v-model="dataEdit.apiSecret" autocomplete="off" placeholder="API Secret"></el-input>
        </el-form-item>
        <el-form-item label="Pass" prop="apiPassphrase" v-if="passPhraseEx(dataEdit.exchanger)">
          <el-input v-model="dataEdit.apiPassphrase" autocomplete="off" placeholder="API Passphrase"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateData()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import statistics from "../api/statistics";

export default {
  data() {

    return {
      tableData: [],
      exchangers: [],
      dialogFormVisible: false,
      loading: true,
      ipLoading: false,
      statLoading: false,
      dataEdit: {},
      rowEditRules: {
        remark: [
          // { validator: validateRemark, trigger: 'blur' }
          {required: true, message: '名称不能为空', trigger: 'blur'},
          {min: 2, max: 30, message: '名称长度在 2 到 30 个字符', trigger: 'blur'}
        ],
        exchanger: [
          {required: true, message: '请选择交易所', trigger: 'blur'},
        ],
        apiKey: [
          {required: true, message: '请输入API Key', trigger: 'blur'},
          {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
        ],
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getData();
    })
  },

  methods: {
    async getData() {
      this.loading = true;
      const res = await statistics.apiList();

      if (res.code === 0) {
        this.loading = false;
        this.tableData = res.data.list;
        this.exchangers = res.data.exchangers;
      }
    },

    updateData() {
      if (this.loading) {
        this.$message({
          message: '数据加载中',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      this.$refs['dataEdit'].validate(async (valid) => {
        if (valid) {
          const res = await statistics.apiSave(this.dataEdit);
          if (res.code === 0) {
            this.$message({
              message: '更新成功',
              type: 'success'
            });
            this.dialogFormVisible = false;
            this.getData().then();
            return true;
          }
        }
        this.loading = false;
        return false;
      });
    },

    async deleteData(id) {
      if (this.loading) {
        this.$message({
          message: '数据加载中',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      const res = await statistics.apiDelete({id: id});
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.dialogFormVisible = false;
        this.getData();
      }
    },

    handleNew() {
      this.dataEdit = {};
      this.rowEditRules.apiSecret = [
        {required: true, message: '请输入API Secret', trigger: 'blur'},
        {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
      ]
      this.rowEditRules.apiPassphrase = [
        {required: true, message: '请输入API Passphrase', trigger: 'blur'},
        {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
      ];
      this.dialogFormVisible = true;
    },

    async handleShowIp() {
      if (this.ipLoading) return;
      this.ipLoading = true;
      const res = await statistics.serverInfo();
      if (res.code === 0) {
        this.$message({
          message: '服务器IP: ' + res.data.ip,
          type: 'success'
        });
      }
      this.ipLoading = false;
    },

    async handleStat() {
      if (this.statLoading) return;
      this.statLoading = true;
      const res = await statistics.apiStat();
      if (res.code === 0) {
        this.$message({
          message: '统计完毕',
          type: 'success'
        });
      }
      this.statLoading = false;
    },

    handleEdit(index, row) {
      this.dataEdit = this.copyRowData(row);
      this.rowEditRules.apiSecret = [
        {required: false, message: '请输入API Secret', trigger: 'blur'},
        {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
      ]
      this.rowEditRules.apiPassphrase = [
        {required: false, message: '请输入API Passphrase', trigger: 'blur'},
        {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
      ];
      this.dialogFormVisible = true;
      this.rowEditRules.apiSecret = [];
    },

    passPhraseEx(exchanger) {
      return exchanger === 'okex' || exchanger === 'kucoin' || exchanger === 'bitget'
    },

    copyRowData(row) {
      return {
        id: row.id,
        remark: row.remark,
        apiKey: row.apiKey,
        exchanger: row.exchanger,
      }
    }
  }
}
</script>

<style scoped lang="scss">
.api-list {
  padding: 2px;
  width: 100%;
}

.api-dialog {
  justify-content: center;
}
</style>
