<template>
  <nav class="comp-nav">
    <el-row type="flex">
      <el-col style="width: 90px">
        <el-dropdown trigger="click" v-if="email">
                    <span class="el-dropdown-link">
                        <img class="comp-nav-menu" src="@/assets/img/BTNChestOfGold.png" alt="">
                    </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/api">
              <el-dropdown-item>API管理</el-dropdown-item>
            </router-link>
            <router-link to="/strategy">
              <el-dropdown-item>策略管理</el-dropdown-item>
            </router-link>
            <router-link to="/telegram">
              <el-dropdown-item>绑定Telegram</el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
        <span class="el-dropdown-link" v-else>
                    <img class="comp-nav-menu" src="@/assets/img/BTNChestOfGold.png" alt="">
                </span>
      </el-col>
      <el-col>
        <div class="comp-nav-path">
          <span>{{ path }}</span>
        </div>
      </el-col>
      <el-col>
        <div class="comp-nav-status" v-if="email">
          <span>{{ email }} </span>
          <span @click="logout">退出</span>
        </div>
        <div class="comp-nav-status" v-else>
          <span @click="toLogin">{{ login }}</span>
        </div>
      </el-col>
    </el-row>

    <div>
      <div>

      </div>
    </div>
  </nav>
</template>

<script>
import cookies from 'js-cookie'
import statistics from '@/api/statistics'

export default {
  name: "Navigation",
  data() {
    return {
      email: '',
      path: '',
      login: '',
      beforeLoginRoute: 'strategy',
    }
  },

  created() {
    this.refresh();

    window.eventHub.on('toLogin', () => {
      this.toLogin();
    });

    window.eventHub.on('afterLogin', () => {
      this.afterLogin();
    });

    window.eventHub.on('refreshNav', () => {
      this.refresh();
      ;
    });
  },

  methods: {
    toLogin() {
      this.refresh();
      if (this.$router.currentRoute.name !== 'login') {
        this.beforeLoginRoute = this.$router.currentRoute.name;
        this.$router.push({name: 'login'});
      } else {
        this.beforeLoginRoute = 'strategy';
      }
    },

    afterLogin() {
      this.refresh();
      this.$router.replace({path: this.beforeLoginRoute});
      this.beforeLoginRoute = 'strategy';
    },

    refresh() {
      const path = this.$router.currentRoute.name;

      this.email = cookies.get('email');
      this.path = this.getCurrentPathName(path);

      // 控制登录文字是否显示
      if (path === 'login') {
        this.login = '';
      } else {
        this.login = '登录';
      }
    },

    getCurrentPathName(path) {
      if (path === 'strategy') {
        return '策略管理';
      } else if (path === 'api') {
        return 'API管理';
      } else if (path === 'telegram') {
        return '绑定Telegram';
      } else {
        return '';
      }
    },

    async logout() {
      const resp = await statistics.logout();
      if (resp.code === 0) {
        this.refresh();
        this.beforeLoginRoute = this.$router.currentRoute.name;
        this.$router.push({path: 'login'});
      }
    },
  }
}
</script>

<style scoped lang="scss">
.comp-nav {
  height: 40px;
  color: #fff;
  background-color: #17191a;
  margin-bottom: 2px;
}

.comp-nav-menu {
  padding-left: 5px;
  padding-top: 5px;
  width: 44px;
}

.comp-nav-path {
  padding-left: 5px;
  padding-top: 13px;
}

.comp-nav-status {
  float: right;
  padding-right: 10px;
  padding-top: 13px;
}
</style>
