function forbiddenScroll() {
  let doc = document.body || document.documentElement.body
  doc.style.overflow = 'hidden'
}

function allowScroll() {
  let doc = document.body || document.documentElement.body
  doc.style.overflow = 'scroll'
}

function getRawParameter(name) {
  let search = location.search
  if (!search) {
    return ''
  }
  let searchArr = search.slice(1).split('&')
  let obj = {}
  searchArr.forEach(item => {
    let key = item.split('=')[0]
    let value = item.split('=')[1]
    if (key && value) {
      obj[key] = value
    }
  })

  return obj[name]
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  var p = results[2].replace(/\+/g, " ");
  if (p.indexOf('%27') > -1 || p.indexOf('%3E') > -1 || p.indexOf('%3C') > -1 || p.indexOf('>') > -1 || p.indexOf('<') > -1) {
    p = parseInt(p);
  } else {
    p = p.replace(/'%28'|'%29'|\(|\)/g, '');
  }

  return decodeURIComponent(p);
}

function replaceParameter(url, k, v) {
  var regex = new RegExp("[?&](" + k + "=([^&#]*)|&|#|$)"), results = regex.exec(url), tag = true;
  if (!results || !results[1])
    tag = false;
  if (tag) {
    return url.replace(results[1], k + '=' + v);
  } else {
    if (url.indexOf('?') > 0) {
      return url + '&' + k + '=' + v;
    } else {
      return url + '?' + k + '=' + v;
    }
  }
}

function copyToClipboard(value, message) {
  const textArea = document.createElement("textarea")
  textArea.value = value

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  textArea.setSelectionRange(0, 99999) /* For mobile devices */

  try {
    const successful = document.execCommand('copy')
    if (successful) {
      alert(message)
    }
  } catch (err) {
    alert('复制到剪切板失败')
  }
  document.body.removeChild(textArea)
}

export default {
  forbiddenScroll,
  allowScroll,
  getParameterByName,
  replaceParameter,
  getRawParameter,
  copyToClipboard,
}
