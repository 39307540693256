<template>
  <div id="app">
    <navigation></navigation>
    <div class="doc-bd flex">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import '@/assets/css/index.scss'

export default {
  name: 'app',
  components: {
    Navigation
  },
  data() {
    return {}
  },
  mounted() {
    document.title = 'Ready to work'
  },
}
</script>

<style scoped lang="scss">
#app {
  height: 100%;
  align-items: center;
}

.doc-hd {
  width: 100%;
  height: 70px;
  background: #3F3F3F;
}

.doc-hd .navigation-bar {
  margin: 0 auto;
  padding: 0 60px;
}

.doc-bd {
  width: 100%;
  height: 100%;
}
</style>
