import axios from 'axios'
import qs from 'qs'
import {Message} from 'element-ui';


axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.withCredentials = true

// http request 拦截器
axios.interceptors.request.use(
  config => {
    // 如果是开发环境，所有请求前加proxy。走一层代理
    if (process.env.NODE_ENV === 'development') {
      config.url = '/proxy' + config.url
    } else if (process.env.NODE_ENV === 'production') {
      config.url = '/api' + config.url
    }
    // 根据请求方法，序列化传来的参数，根据后端需求是否序列化
    if (!config.file) {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  err => {
    Message({
      type: 'error',
      message: "接口请求异常 " + err,
      showClose: true,
      duration: 0,
    });
    return Promise.resolve(err)
  }
);

// 响应统一处理
axios.interceptors.response.use(response => {
  let res = response.data;
  if (res) {
    if (res.code > 0) {
      Message({
        type: 'warning',
        message: res.msg,
        showClose: true,
        duration: 6000,
      });
      if (res.code === 1) {
        window.eventHub.fire('toLogin');
      }
    }
    return Promise.resolve(res)
  }
}, (err) => {
  // success/warning/info/error
  return Message({
    type: 'error',
    message: "接口调用异常 " + err,
    showClose: true,
    duration: 0,
  });
});

export const api = axios;
